export const environment = {
  production: true,
  accept: "application/json",
  content_type: "application/json",

  //__TES__
  // imgUrl: 'https://img-test.server.bookeyond.com/',
  // apiUrl: "https://api-test.server.bookeyond.com",
  // audioUrl: "https://api-test.server.bookeyond.com",
  
  //__PRODUCTION__
  imgUrl: "https://img.bookeyond.com/",
  apiUrl: "https://aapi.bookeyond.com",
  audioUrl: "https://aapi.bookeyond.com",
};
